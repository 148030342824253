<template>
<div class="scope">
<!--  导航栏-->
  <div class="dh">
    <div class="navigation">
      <div class="ico" @click="go()">
        <img class="icon" src="../assets/img15.png">
        <p class="designation">华钛信息</p>
      </div>
      <div class="selectivity">
        <div class="alternative">
          <div class="xzx1 xzxz1">
            <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
            <div class="nav1 nav">
              <div class="navxx">
                <span>行业方案</span>
                <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzData" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img188.png">
              </div>
            </div>
          </div>
          <div class="xzx2 xzxz1">
            <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
            <div class="nav2 nav">
              <div class="navxx">
                <span>自主产品</span>
                <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img189.png">
              </div>
            </div>
          </div>
          <div class="xzx3 xzxz1">
            <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
            <div class="nav3 nav">
              <div class="navxx">
                <span>服务范围</span>
                <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img190.png">
              </div>
            </div>
          </div>
          <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
            <span class="xzxz" @click="din(item)">{{item.cname}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--第一层-->
  <div class="n-first">
    <div class="zss">
      <div class="sbb">
        <div class="sbb1">
          <img class="tb-im" src="../assets/img212.png">
          <div class="tb-nr">
            <span class="zb">优质信息化服务</span>
            <span class="fb">华钛信息经过多年的技术沉淀，主要有定制化开发、产品化解决方案、平台研发等八大服务领域，以合作共赢的服务理念为核心，为企业提供优质化服务</span>
            <div class="sxx">
              <div class="dxx">
                <img src="../assets/img213.png">
                <span>2年技术锤炼，钻研信息技术，多年技术沉淀</span>
              </div>
              <div class="dxx">
                <img src="../assets/img213.png">
                <span>100+行业方案，挖掘业务痛点，打造核心方案</span>
              </div>
              <div class="dxx">
                <img src="../assets/img213.png">
                <span>220+优质服务，涉及企业信息化各个角落</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="xbb">
        <div class="nia" v-for="(item, index) in niData" :key="index">
          <div class="sz">
            <span>{{item.num}}</span>
            <span>{{item.ci}}</span>
          </div>
          <div class="js">{{item.js}}</div>
        </div>
      </div>
    </div>
  </div>
  <!--   第二层-->
  <div class="clien-second">
    <div class="spreadhead">
      <div class="subhead">
        <div class="moit">
          <img class="img3" src="../assets/img3.png">
          <span>服务范围</span>
          <img class="img4" src="../assets/img4.png">
        </div>
      </div>
    </div>
    <div class="option-box">
      <div class="box3">
        <div class="zss">
          <div class="box4">
            <div class="outer" v-for="(item , index) in loopDatas" :key="index">
              <img :src="item.lmg">
              <span>{{item.cli1}}</span>
              <span>{{item.cli2}}</span>
              <span @click="din(item)">{{item.cli3}}
              <img src="../assets/img11.1.png">
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--    底层-->
  <div class="ground-floor">
    <div class="fbox">
      <div class="fbox1">
        <div class="fbox2">
          <div class="lianxi">
            <div class="fico">
              <img class="ficon" src="../assets/img2.png">
              <p class="fdesignation">华钛信息</p>
            </div>
            <div class="contway">
              <div class="special">
                <span>联系电话：</span>
                <span>021-55962658</span>
              </div>
              <div class="special">
                <span>商务合作：</span>
                <span>business@huataish.com</span>
              </div>
            </div>
          </div>
          <div class="cut-line"></div>
          <div class="explain">
            <div class="explain1">
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">行业方案</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                      <span @click="din(item)">{{ item.lei }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">自主产品</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                      <span @click="din(item)">{{ item.lei1 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">服务范围</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                      <span @click="din(item)">{{ item.lei2 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="cl()">客户案例</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="hz()">合作伙伴</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="xx()">华钛信息</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                      <span @click="din(item)">{{ item.lei4 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cut-line1"></div>
        <div class="safety">
          <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "scope",
  data() {
    return {
      cur: 0,
      niData:[
        {
          num:2,
          ci:"年+",
          js:"技术锤炼，坚实的行业基础"
        },
        {
          num:220,
          ci:"次+",
          js:"优质的企业服务"
        },
        {
          num:200,
          ci:"次+",
          js:"企业客户，经验丰富，生态互通"
        },
        {
          num:100,
          ci:"个+",
          js:"行业化解决方案，引领智变"
        },
      ],
      hzData:[
        {
          hzz:"金融行业",
          url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      loopDatas: [
        {
          lmg:require('../assets/img154.png'),
          cli1:"定制化开发服务",
          cli2:"华钛信息咨询服务包括数字化转型及交互设计，为企业提供专业化的咨询服务",
          cli3:"了解更多",
          url:'/CustoMization'
        },
        {
          lmg:require('../assets/img156.png'),
          cli1:"平台研发服务",
          cli2:"以大数据、云计算技术，提供专业的数字化服务，驱动商业模式创新",
          cli3:"了解更多",
          url:'/PlatForm'
        },
        {
          lmg:require('../assets/img158.png'),
          cli1:"产品解决服务",
          cli2:"挖掘业务痛点，剖析行业趋势，致力于打造符合业务需求的一体化产品解决方案",
          cli3:"了解更多",
          url:'/Product'
        },
        {
          lmg:require('../assets/img155.png'),
          cli1:"咨询服务",
          cli2:"华钛信息咨询服务包括数字化转型及交互设计，为企业提供专业化的咨询服务",
          cli3:"了解更多",
          url:'/DigiTization'
        },
        {
          lmg:require('../assets/img159.png'),
          cli1:"运营服务",
          cli2:"为企业业务运营难点提供专业化的解决方案，助力企业提升运营效能",
          cli3:"了解更多",
          url:'/OperAtion'
        },
        {
          lmg:require('../assets/img157.png'),
          cli1:"外包服务",
          cli2:"华钛信息提供完善的IT外包服务流程，从人员培养到服务落地",
          cli3:"了解更多",
          url:'/Epiboly'
        },
      ],
      foolDatas: [
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "金融行业",
          url: '/FinAnce'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/ectocyst'
        },
      ],
      foolDataes: [

      ],
      foolDataess: [

      ],
    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')

    },
    xx(){
      window.open('/information','_blank')

    },
    cl(){
      window.open('/Clien','_blank')
    }
  }
}
</script>

<style scoped>
.scope{
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  min-width: 1230px;
  position: relative;
  width: 100%;
}
/*第一层*/
.n-first{
  width: 100%;
  height: 737px;
  background-image: url("../assets/img152.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.n-first .zss{
  display: flex;
  flex-direction: column;
}
.n-first .zss .sbb{
  width: 1180px;
  height: 536px;
  margin: 0 auto;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.78) 0%, rgba(255, 255, 255, 0.63) 100%);
  border-radius: 4px;
  backdrop-filter: blur(18px);
}
.n-first .zss .sbb .sbb1{
  display: flex;
  width: 100%;
  height: 371px;
  margin-top: 106px;
  justify-content: space-between;
}
.n-first .zss .sbb .sbb1 .tb-im{
  display: block;
  width: 713px;
  height: 371px;
  margin-left: 40px;
}
.n-first .zss .sbb .sbb1 .tb-nr{
  width: 322px;
  height: 100%;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.n-first .zss .sbb .sbb1 .tb-nr .zb{
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 42px;
}
.n-first .zss .sbb .sbb1 .tb-nr .fb{
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 24px;
}
.n-first .zss .sbb .sbb1 .tb-nr .sxx{
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}
.n-first .zss .sbb .sbb1 .tb-nr .sxx .dxx{
  width: 100%;
  display: flex;
  margin-bottom: 14px;
  align-items: center;
}
.n-first .zss .sbb .sbb1 .tb-nr .sxx .dxx img{
  display: inline-block;
  width: 14px;
  height: 10px;
}
.n-first .zss .sbb .sbb1 .tb-nr .sxx .dxx span{
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 20px;
  text-align: left;
  margin-left: 8px;
}
.n-first .zss .xbb{
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.n-first .zss .xbb .nia{
  width: 224px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  color: #FFFFFF;
  margin-top: 56px;
}
.n-first .zss .xbb .nia .sz{
  height: 22px;
  margin: 0 auto;
}
.n-first .zss .xbb .nia .sz span:first-child{
  font-size: 70px;
  font-family: DINCondensed-Bold, DINCondensed;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 84px;
}
.n-first .zss .xbb .nia .sz span:last-child{
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
}
.n-first .zss .xbb .nia .js{
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 22px;
  text-align: center;
}
/*标题*/
.spreadhead {
  width: 100%;
  height: 122px;
  margin-bottom: 48px;
  /*background: red;*/
}

.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}

.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 272px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}

.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}

.spreadhead .subhead .moit span {
  display: block;
  width: 96px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
}

.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #666F8F;
  margin: auto;
}
/*第二层*/
.clien-second{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1158px;
  background: #F9FAFF;
}
.clien-second .option-box{
  flex: 1;
}
.clien-second .option-box .gao1{
  display: flex;
  flex-direction: column;
  width: 1180px;
  height: 334px;
  margin: 0 auto;
  /*background: #262C50;*/
}
.clien-second .option-box .gao1 .gao1-tab{
  width: 1180px;
  height: 39px;
  margin-bottom: 60px;
  border-bottom: 1px solid #D8D8D8;
}
.clien-second .option-box .gao1 .gao1-tab ul{
  width: 900px;
  height: 39px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.clien-second .option-box .gao1 .gao1-tab ul li{
  width: 56px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  text-align: center;
}
.clien-second .option-box .gao1 .gao1-tab ul li p:first-child.active{
  color: #124BFF;
}
.clien-second .option-box .gao1 .gao1-tab ul li .underline{
  margin-top: 14px;
  width: 56px;
  height: 2px;
  background: #124BFF;
}
.clien-second .option-box .gao2{
  flex: 1;
}
.clien-second .option-box .gao2 img{
  display: block;
  width: 1180px;
  height: 234px;
}
.box3 {
  width: 100%;
  height: 848px;
  margin-top: 48px;
}

.box4 {
  display: flex;
  flex-direction: row;
  height: 848px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.box4 .outer {
  display: flex;
  flex-direction: column;
  height: 416px;
  border-radius: 4px;
  border: 1px solid rgba(229, 231, 239, 1);
  width: 380px;
  margin: 0 16px 20px 0;
  box-shadow: 0px 0px 20px 0px #E6EAF3;
  background: #FFFFFF;
}

.box4 .outer span:nth-child(4){
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #124BFF;
  line-height: 22px;
  margin-top: 24px;
  margin-left: 30px;
}
.box4 .outer:hover span:nth-child(4) img{
  position: relative;
  left: 8px;
  transform: all 1s;
}

.box4 .outer span:nth-child(2){
  width: 140px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #292C3E;
  line-height: 28px;
  margin-top: 28px;
  margin-left: 30px;
}

.box4 .outer span:nth-child(3){
  width: 304px;
  height: 44px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9699AD;
  line-height: 22px;
  margin-top: 12px;
  margin-left: 30px;
}

.outer:nth-child(3n) {
  margin-right: 0;
}

.outer:nth-last-child(-n + 3) {
  margin-bottom: 0;
}

</style>
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}
</style>